<template>
    <div class="checkTask">
      <!-- top -->
      <el-breadcrumb separator="/">
          <el-breadcrumb-item>机构信息</el-breadcrumb-item>
          <el-breadcrumb-item>科室管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="tableBox">
          <!-- blue_title -->
            <div class="blueTitle" style="margin-left:12px;">科室管理</div>
          <div class="tableTop">
             <div class="table_l">
                <div class="add" @click="add">增加科室</div>
             </div>
            <div class="table_r">
                <!--  全部---- <div class="result">
                    <el-select v-model="submitOption" @change="selectResult" style="width: 152px !important">
                        <el-option
                            v-for="item in resultOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div> -->
                <div class="search">
                    <el-input
                            placeholder="请输入内容"
                            prefix-icon="el-icon-search"
                            @change="search"
                            v-model="seachAll"
                            class="elSerch"
                            >
                            <template slot="append" style="background-color: #00CCCC;cursor:pointer">搜索</template>
                    </el-input>
                </div>
            </div>
          </div>
           <DepartmentTable ref="uetable"/>
      </div>
      <!-- 禁用弹窗 -->
      <el-dialog
            :visible.sync="openActive"
            :show-close="false"
            width="432px"
            height="168px"
            center>
            <span>禁用后该科室的排版规则将会下周开始失效，是否确认禁用？</span>
            <span slot="footer" class="dialog-footer">
                <div class="footer1" @click="openCancel">取 消</div>
                <div class="footer2" @click="openSure">禁用</div>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import DepartmentTable from '@/components/table/departmentTable.vue'
import {getkeshiList,updateDept} from '@/api/order.js'
export default {
   
    components:{
        DepartmentTable,
    },
    data(){
        return{
           secahAll:null,
           itemId:null,
           openActive:false,
           //评估结果选择
           resultOption:[ {
                            label:'全部',
                            value: null
                        }, {
                            label:'已预约',
                            value: '高',
                        },
                        {
                            label:'已取消',
                            value:"中",
                        }
                        ],
        seachAll:null,
        submitOption:"全部",
        }
    },
  
    methods:{
        //评估结果选择
        // selectResult(e){
        //     this.evaValue= e;
        // },
        //搜索
        search(e){
            this.$refs.uetable.getData(1,10,e);
        },
        //新增科室
        add(){
            this.$router.push({
          path:'/addDepart',
        })
        },
        //取消禁用
        openCancel(){
            this.openActive = false;
            this.$refs.uetable.getData(1,10);
        },
                //确认禁用
        openSure(){
            updateDept({
                id:this.itemId,
                status:"false"
            }).then(res=>{
                if(res.data.status==200){
                    this.$message({
                    message: '更新成功！',
                    type: 'success'
                    });
                    this.$refs.uetable.getData(1,10);
                    this.openActive = false;
                }else{
                    this.$message.error(res.data.message);
                    this.$refs.uetable.getData(1,10);
                }
            })
            
        },
    }
}
</script>
<style scoped lang="stylus">
//change elemen-ui style
//面包屑样式
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }
//下拉框icon样式
/deep/.el-input__icon {
    line-height: 2.4 !important;
}
//搜索框样式--边框颜色
.elSerch>>>.el-input__inner
    border: 1px solid #00cccc !important
    width: 200px
    border-radius: 4px 0 0 4px
//搜索框样式--高度
/deep/.el-input__inner{
    height: 32px;
  }
//搜索框样式--搜索部分样式
/deep/.el-input-group__append{
    border-block-color:#00CCCC !important;
    color: #fff !important;
    background-color: #00CCCC !important;
    border: 0 !important;
  }

  /deep/.dialog-footer{
    display: block !important;
    width: 284px !important;
    height: 32px !important;
    justify-content: space-between;
    margin:  0 auto !important;
    display: flex !important;
}
/deep/.footer1{
    width: 120px;
    height: 32px;
    background-color: #FFFFFF;
    border-radius:4px; 
    color: #00cccc;
    font-size: 14px;
    line-height: 32px;
    cursor pointer
    text-align: center;
    border: 1px solid #00cccc;
}
/deep/.footer2{
    width: 120px;
    height: 32px;
    background-color: #00cccc;
    border-radius:4px; 
    color: #fff;
    font-size: 14px;
    line-height: 32px;
    cursor pointer
    text-align: center;
}


//component style
.blueTitle
  font-size: 18px 
  color: #353535
  display: flex
  align-items: center 
  position: relative
  &::before
    width: 3px
    height: 18px
    content: ""
    position: absolute
    border-radius: .03rem
    left: -10px
    bottom:6px
    background-color: #00CCCC
.seachText
    font-size: 14px
    color: #AEAEAE
//normal style
.checkTask
    overflow hidden
    .tableBox
        overflow hidden
        margin-top: 16px
        padding: 20px
        background-color: #FFFFFF
        .tableTop
            display: flex
            margin-top: 20px
            margin-bottom: 30px
            align-items: center
            justify-content: space-between
            .table_l
                display: flex
                .add
                    width: 140px 
                    height: 32px 
                    background-color: #00cccc 
                    text-align: center
                    font-size: 16px 
                    border-radius: 2px 
                    font-size: 14px
                    color: #fff
                    line-height: 32px 
                    cursor pointer


            .table_r
                display: flex
                
                .search
                    height: 32px
                    margin-left: 16px
                    display: flex
                    .secbox
                        width: 88px
                        height: 32px  
                        background-color: #00cccc
                        text-align: center
                        line-height: 32px
                        font-size: 14px
                        color: #fff
            
</style>